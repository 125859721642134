const Contact = () => {
    return (  
    <div>
             <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1>Contact Page</h1>
            <br></br>
            <br></br>
            <p>Karan Mishra(CEO) Mobile Number: +91-88001 90304</p>
            <p>Email: contact@veggiesupply.in</p>
            <p>Email: veggiesupply1@gmail.com</p>
            <br></br>
            <br></br>
            <br></br>
            <p>Message box</p>
            <br></br>
            <br></br>
            <br></br> <br></br>
            <br></br>
            <br></br>
            <p>Locate Us</p>
            <div>
              <div>
                Work Address
                Azadpur Sabzi Mandi New Delhi
              </div>
              <div>
                Corporate Office Address
      Gamma-1, Greater Noida, Uttar Pradesh-201310
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br> <br></br>
            <br></br>
            <br></br>  <br></br>
            <br></br>
            <br></br> <br></br>
            <br></br>
            <br></br>  <br></br>
            <br></br>
            <br></br> <br></br>
            <br></br>
            <br></br>  <br></br>
            <br></br>
            <br></br> <br></br>
            <br></br>
            <br></br>

  </div>
    );
}
 
export default Contact;