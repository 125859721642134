import React from 'react';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Includes Popper.js and jQuery
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  return (
    <header className="header">
		<div className="container">
		<Link to='/' className="logo"><p className='brandName'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VeggieSupply</p></Link>
		<input className="menu-btn" type="checkbox" id="menu-btn"></input>
			<label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
			<ul className="menu">
      <li><Link to='/'>Home</Link></li>
      <li><Link to='/about-us'>Leadership</Link></li>
      <li><Link to='/service'>Services</Link></li>
	  <li><Link to='/our-customer'>Customer</Link></li>
      <li><Link to='/contact'>Contact Us</Link></li>			
			</ul>
		</div>
		
	</header>
  );
}

export default Navbar;