import imgmain from '../assests/7807053-4f894c42.jpg'


const Mainpage = () => {
    return (  
        <div >
<div class="image-container">
    <img src={imgmain} alt="Our Vision" class="background-image" />
    <div class="overlay-text">
        <p className="visionTitle">Our Vision</p>
        <p className="visionText">Our vision is to become the preferred company to serve the hotel and hospitality business (B2B) for the supply of fruits, vegetables, and other items, through an IT-enabled user-friendly mobile app.</p>
    </div>
</div>


   <br></br>
            <br></br>
            <p className="visionTitle">Our Values</p>
            <p className="visionText"><strong>Integrity</strong></p> 
            <p className="visionText">Integrity is the quality of being honest and having strong moral values.</p>
<p className="visionText"><strong>Trust & Respect</strong></p><p className="visionText"> Respect is earned, and trust is gained.</p>
<p className="visionText"><strong>Commitment</strong></p><p className="visionText"> We believe commitment is the power that transforms a promise into reality. Where there's a will, there's always a way.</p>  
   <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>       <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>       <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>      
  </div>
    );
}
 
export default Mainpage;