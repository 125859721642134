import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Navbar from "./components/Navbar"
import Footer from './components/Footer';
import Main from "./pages/Main"
import Leadership from './pages/leadership';
import Contact from './pages/contact';
import Service from './pages/services';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Navbar />
      <div className="pages">
        <Routes>
          <Route path="/" element={<Main/> }/>
          <Route path="/about-us" element={<Leadership/> }/>
          <Route path="/service" element={<Service/> }/>
          <Route path="/contact" element={<Contact/> }/>
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  </div>
  );
}

export default App;
