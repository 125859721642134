import img1 from '../assests/1718215622894.png'

import img3 from '../assests/karanphoto.jpeg'
import img4 from '../assests/1721738642151.png'


const Leadership = () => {
    return (
        <div>
            <br></br>
            <br></br>
            <section>
  <h2 className="leadershipHeading">our team</h2>
  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Animi, praesentium veritatis voluptatibus ut consequuntur quas consequatur omnis id rem obcaecati.</p>
  <div class="cards">
    <div class="card">
      <div class="card-img-wrapper">
        <img src={img1} alt=""></img>
      </div>
      <a href="https://www.linkedin.com/in/sanjay-dubey-a50a7b87" class="social-icon"><i class="fa-brands fa-linkedin-in"></i></a>
      <div class="card-content-wrapper">
        <a href="#" class="card-content">
          <h3>Sanjay Dubey</h3>
          <h6 className='leadershipdesgination'>MD & Co-Founder</h6>
          <div>
            <p className='leadershipdescription'>Mechanical engineer (B. Tech) and Postgraduate in Management from Harvard Business School, 23 years of experience in Leadership roles in the area of supply chain, operation, P&L, manufacturing, logistics etc. Japanese(Honda Cars) and American multinationals(Ingersoll Rand) and leading Indian companies (Patanjali Ayurveda, Dalmia Cement, RSPL)</p>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg> */}
          </div>

        </a>
      </div>
    </div>
    <div class="card">
      <div class="card-img-wrapper">
        <img src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="James Newton"></img>
      </div>
      <a href="#" class="social-icon"><i class="fa-brands fa-x-twitter"></i></a>
      <div class="card-content-wrapper">
        <a href="#" class="card-content">
          <h3>Sushil Mishra</h3>
          <h6>JMD & Co-Founder</h6>
          <div>
            <p>MA(Physiology) Postgraduate and Traditional Adhati (आढती) Businessman popular as "mishraji" in Azadpur Sabzi Mandi, having 30+ years of experience in managing adhati business(Sales and Purchase) in Azadpur Mandi - New Delhi having PAN India connects with farmers and other mandi businessman.</p>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg> */}
          </div>

        </a>
      </div>
    </div>
    <div class="card">
      <div class="card-img-wrapper">
        <img src={img3} alt=""></img>
      </div>
      <a href="#" class="social-icon"><i class="fa-brands fa-linkedin-in"></i></a>
      <div class="card-content-wrapper">
        <a href="#" class="card-content">
          <h3>Karan Mishra</h3>
          <h6>CEO & Co-Founder</h6>
          <div>
            <p>Mechanical Engineer with 5 years of experience in Sales and Marketing for B2B.            </p>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg> */}
          </div>

        </a>
      </div>
    </div>
    <div class="card">
      <div class="card-img-wrapper">
        <img src={img4} alt=""></img>
      </div>
      <a href="https://www.linkedin.com/in/priyanshu-dubey-759794223/" class="social-icon"><i class="fa-brands fa-linkedin-in"></i></a>
      <div class="card-content-wrapper">
        <a href="#" class="card-content">
          <h3>Priyanshu Dubey</h3>
          <h6>CTO & Co-Founder</h6>
          <div>
            <p>IIIT Lucknow Computer Science & Business graduate (B. Tech) and schooling from DPS Noida and gold medalist in ICO</p>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg> */}
          </div>

        </a>
      </div>
    </div>
  </div>
</section>
            <br></br><br></br>
            <br></br>
            <br></br>
            <br></br><br></br>
            <br></br>
            <br></br>
            <br></br><br></br>

        </div>
//         <div>
//         <br></br>
//             <br></br> <br></br>
//             <br></br>
//  <div id="index-gallery">
//   <div class="item">
//     <img src="." alt=""/>
//     <p>My Caption here</p>
//   </div>

//   <div class="item">
//     <img src="http://movingimages.files.wordpress.com/2009/10/earth-simulator-offers-peaks-into-our-planetary-future.jpg?w=780" alt=""/>
//     <p>My Caption here</p>
//   </div>

//   <div class="item">
//     <img src="http://movingimages.files.wordpress.com/2009/10/earth-simulator-offers-peaks-into-our-planetary-future.jpg?w=780" alt=""/>
//     <p>My Caption here</p>
//   </div>

//   <div class="item">
//     <img src="http://movingimages.files.wordpress.com/2009/10/earth-simulator-offers-peaks-into-our-planetary-future.jpg?w=780" alt=""/>
//     <p>My Caption here</p>
//   </div>
// </div>
//         </div>
    );
}

export default Leadership;