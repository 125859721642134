const Footer = () => {
    return ( 
        <footer>
        <div class="wrapper">
          <small><strong>VeggieSupply &copy; 2024 </strong></small>
          <small>All Rights Reserved</small>
          <nav class="footer-nav">
          </nav>
        </div>
      </footer>
     );
}
 
export default Footer;